import axios from "axios"

//export const BASE_URL = "https://3882-187-137-196-246.ngrok-free.app/v1" // Replace with your API base URL
export const BASE_URL = "https://ranchogrande.gsiempre.com/api/v1" // Replace with your API base URL
//export const BASE_URL = "http://localhost:3001/v1"

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, {
      usuario: email,
      password,
    })

    // Store the token or perform any necessary operations
    // @ts-ignore
    const { token } = response.data
    // Return the token or any relevant data
    return token
  } catch (error) {
    console.error("Login error:", error)
    throw error
  }
}

export const logout = async () => {
  try {
    localStorage.removeItem("token")
  } catch (error) {
    console.error("Logout error:", error)
    throw error
  }
}

export const postWithToken = async (
  url: string,
  token: string | null,
  data: any
) => {
  try {
    if (!token) {
      throw new Error("Token not found")
    }

    const response = await axios.post(`${BASE_URL}/${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error("Post request error:", error)
    throw error
  }
}

export const getWithToken = async (url: string, token: string | null) => {
  try {
    if (!token) {
      throw new Error("Token not found")
    }

    const response = await axios.get(`${BASE_URL}/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error("GET request error:", error)
    throw error
  }
}
