import styled from "styled-components"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
`

export const LogoWrapper = styled.div`
  width: 64px;
  height: 64px;
  width: 100%;
  margin-bottom: 20px;
`

export const CollapseWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 8px;
`

export const AvatarWrapper = styled.div`
  margin-left: auto;
`

export const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-right: 20px;
`

export const LogoutButton = styled.button`
  padding: 0px 10px;
  height: 40px;
  border: none;
  background-color: #6a6a64;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background-color: #d9363e;
  }
`
export const UserText = styled.span`
  font-size: 16px;
  color: #333;
`