import React, { useContext, useEffect, useState } from "react"
import { Wrapper } from "./Usuarios.styles"
import { Button, DatePicker, Space, Table, Select } from "antd"
import { ProduccionActions } from "../Services/produccion"
import { AuthContext } from "../Contexts/AuthContext"
import { ColumnsType } from "antd/es/table"
import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import AdvancedFormat from "dayjs/plugin/advancedFormat"
import { RangePickerWrapper } from "./Reporte.styles"
import { useGeneralContext } from "../Contexts/GeneralContext"
import { BASE_URL } from "../Services/apiService"
import { ParvadasActions } from "../Services/parvadas"
import { PosturasActions } from "../Services/posturas"

interface ReporteRenglon {
  fecha: string
  nompostura: string
  nomcaseta: string
  lote: number

  unidades: number
  kilogramos: number

  limpio_cant: number
  limpio_kgs: number
  roto_cant: number
  roto_kgs: number
  debil_cant: number
  debil_kgs: number
  sucio_cant: number
  sucio_kgs: number
  rescatado_cant: number
  rescatado_kgs: number
  alimento_servido: number
  aves_muertas: number
  peso_corporal: number
}

interface Parvada {
  id: string
  id_postura: string
  lote: number
  nompostura: string
}

type RangeValue = [Dayjs | null, Dayjs | null] | null

export const ReporteScreen: React.FC = () => {
  const { RangePicker } = DatePicker
  const { token, user } = useContext(AuthContext)
  const { setTitulo } = useGeneralContext()

  const [reporte, setReporte] = useState<ReporteRenglon[]>([])
  const [dateRangeValue, setDateRangeValue] = useState<RangeValue>([
    dayjs(),
    dayjs(),
  ])

  //const para los filtros del reporte
  const [parvadas, setParvadas] = useState<Parvada[]>([])
  const [selectedParvada, setSelectedParvada] = useState<string>("all")

  const productosActions = ProduccionActions(token)
  const { getParvadas } = ParvadasActions(token)
  const { getPosturas } = PosturasActions(token)

  useEffect(() => {
    setTitulo("Reporte")
  }, [])

  const fetchReporte = async (
    dateRange: [Dayjs | null, Dayjs | null],
    postura?: string,
    lote?: string
  ) => {
    try {
      const filtrosAdicionales =
        selectedParvada !== "all" ? [selectedParvada] : []

      const data = await productosActions.postReporte(
        dateRange[0]?.format("YYYYMMDD") || "",
        dateRange[1]?.format("YYYYMMDD") || "",
        filtrosAdicionales
      )

      setReporte(data)
    } catch (error) {
      console.error("Error fetching reporte de produccion:", error)
    }
  }

  const fetchAllParvadas = async () => {
    try {
      const posturasResponse = await getPosturas()

      const filteredPosturas = posturasResponse.filter(
        (postura: any) =>
          user?.permisos?.posturas.find(
            (permiso: any) => permiso.id === postura.id
          )?.activo || false
      )

      const parvadasPromises = filteredPosturas.map((postura: any) =>
        getParvadas(postura.id)
      )
      const parvadasResults = await Promise.all(parvadasPromises)
      const allParvadas = parvadasResults.flat()
      const filteredParvadas = allParvadas
        .filter((parvada) => parvada !== null && parvada.activo > 0)
        .map((parvada: Parvada) => {
          const postura = posturasResponse.find(
            (postura: any) => postura.id === parvada.id_postura
          )

          return {
            id: parvada.id,
            id_postura: parvada.id_postura,
            lote: parvada.lote,
            nompostura: postura.nombre,
          }
        })

      setParvadas(filteredParvadas)
    } catch (error) {
      console.error("Error fetching parvadas:", error)
    }
  }

  useEffect(() => {
    fetchAllParvadas()
  }, [])

  const handleParvadaChange = (value: string) => {
    setSelectedParvada(value)
  }

  useEffect(() => {
    // Fetch the product data from the backend
    //    fetchReporte(dateRangeValue || [dayjs(), dayjs()])
    if (selectedParvada === "all") {
      fetchReporte(dateRangeValue || [dayjs(), dayjs()])
    } else {
      const parvada = parvadas.find((p) => p.id === selectedParvada)
      if (parvada) {
        fetchReporte(
          dateRangeValue || [dayjs(), dayjs()],
          parvada.nompostura,
          parvada.lote.toString()
        )
      }
    }
  }, [dateRangeValue, selectedParvada])

  const columns: ColumnsType<ReporteRenglon> = [
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      render: (fechaInicio: string) => {
        dayjs.extend(customParseFormat)
        dayjs.extend(AdvancedFormat)
        return dayjs(`${fechaInicio}`, "YYYYMMDD").format("DD/MM/YYYY")
      },
    },
    {
      title: "Postura",
      dataIndex: "nompostura",
      key: "nompostura",
      width: 300,
    },
    { title: "Caseta", dataIndex: "nomcaseta", key: "nomcaseta", width: 300 },
    { title: "Lote", dataIndex: "lote", key: "lote", align: "right" },
    {
      title: "Limpio Cant",
      dataIndex: "limpio_cant",
      key: "limpio_cant",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Limpio Kgs",
      dataIndex: "limpio_kgs",
      key: "limpio_kgs",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
        </span>
      ),
    },
    {
      title: "Debil Cant",
      dataIndex: "debil_cant",
      key: "debil_cant",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Debil Kgs",
      dataIndex: "debil_kgs",
      key: "debil_kgs",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
        </span>
      ),
    },
    {
      title: "Roto Cant",
      dataIndex: "roto_cant",
      key: "roto_cant",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Roto Kgs",
      dataIndex: "roto_kgs",
      key: "roto_kgs",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
        </span>
      ),
    },
    {
      title: "Sucio Cant",
      dataIndex: "sucio_cant",
      key: "sucio_cant",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Sucio Kgs",
      dataIndex: "sucio_kgs",
      key: "sucio_kgs",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
        </span>
      ),
    },
    {
      title: "Rescatado Cant",
      dataIndex: "rescatado_cant",
      key: "rescatado_cant",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Rescatado Kgs",
      dataIndex: "rescatado_kgs",
      key: "rescatado_kgs",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
        </span>
      ),
    },
    {
      title: "Total Unidades",
      dataIndex: "unidades",
      key: "unidades",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Total Kilogramos",
      dataIndex: "kilogramos",
      key: "kilogramos",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
        </span>
      ),
    },
    {
      title: "Alimento Servido",
      dataIndex: "alimento_servido",
      key: "alimento_servido",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Aves Muertas",
      dataIndex: "aves_muertas",
      key: "aves_muertas",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", { maximumFractionDigits: 0 })}
        </span>
      ),
    },
    {
      title: "Peso Corporal",
      dataIndex: "peso_corporal",
      key: "peso_corporal",
      align: "right",
      render: (value: number) => (
        <span>
          {value.toLocaleString("es-MX", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          })}
        </span>
      ),
    },
  ]

  return (
    <Wrapper>
      <RangePickerWrapper>
        <RangePicker
          value={dateRangeValue}
          onChange={(val) => {
            setDateRangeValue(val)
          }}
          changeOnBlur
          format={"DD/MM/YYYY"}
        />
        <Select
          style={{ width: 300, marginLeft: 16 }}
          value={selectedParvada}
          onChange={handleParvadaChange}
          options={[
            { value: "all", label: "Todas las parvadas" },
            ...parvadas.map((parvada) => {
              return {
                value: parvada.id,
                label: `${parvada.nompostura} / Lote ${parvada.lote}`,
              }
            }),
          ]}
        />
        <div style={{ flex: 1 }}></div>
        <Button
          type="primary"
          onClick={() => {
            window.open(
              `${BASE_URL}/descargarcsv?fecha_inicial=${dateRangeValue?.[0]?.format(
                "YYYYMMDD"
              )}&fecha_final=${dateRangeValue?.[1]?.format("YYYYMMDD")}${
                selectedParvada !== "all" ? `&parvadas=${selectedParvada}` : ""
              }&token=${token}`
            )
          }}
        >
          Descargar CSV
        </Button>
      </RangePickerWrapper>
      <Table dataSource={reporte} columns={columns} rowKey="id" />
    </Wrapper>
  )
}
