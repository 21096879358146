// File: AuthContext.tsx

import React, { createContext, useEffect, useState } from "react"
import { getWithToken } from "../Services/apiService"
import { Buffer } from "buffer"

interface User {
  nombre: string
  email: string
  photoURL?: string
  permisos: {
    posturas: { id: string; activo: boolean }[]
    casetas: { id: string; activo: boolean }[]
  }
}

interface AuthContextProps {
  user: User | null
  token: string | null
  login: (newToken: string) => void
  logout: () => void
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  token: "",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
})

interface AuthProviderProps {
  children: any
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null)
  const [token, setToken] = useState<string | null>(null)

  const getUserData = async (tokenUser: string) => {
    try {
      const response = await getWithToken(`usuarios/GetCurrentUser`, tokenUser)

      const cleanResponse = {
        ...response,
        permisos: {
          posturas: response.permisos.length
            ? JSON.parse(
                Buffer.from(response.permisos, "base64").toString("ascii")
              )
            : [],
        },
      }

      setUser(cleanResponse)
    } catch (error) {
      console.error("Error fetching user:", error)
      logout()
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token")

    if (token !== null) {
      setToken(token)
    }
  }, [])

  useEffect(() => {
    if (token !== null) {
      getUserData(token)
    }
  }, [token])

  const login = (newToken: string) => {
    setToken(newToken)
    // You can perform any necessary operations with the token
    localStorage.setItem("token", newToken)
  }

  const logout = () => {
    setToken(null)
    setUser(null)
    // You can perform any necessary cleanup
    localStorage.removeItem("token")
  }

  const authContextValue: AuthContextProps = {
    user,
    token,
    login,
    logout,
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}
