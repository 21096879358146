import React, { useContext, useState } from "react"
import { Image, Layout, Menu, Space, Typography } from "antd"
import {
  //MenuUnfoldOutlined,
  //MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { Link, redirect } from "react-router-dom"
//import UserAvatar from "../Components/UserAvatar"
import {
  //AvatarWrapper,
  //HeaderWrapper,
  LogoWrapper,
  LogoutButton,
  UserText
} from "./BaseNavigation.styles"
import { AuthContext } from "../Contexts/AuthContext"
import logo from "../assets/logo-rancho-grande.jpg"
import { useGeneralContext } from "../Contexts/GeneralContext"
import { logout } from "../Services/apiService"

const { Header, Sider, Content, Footer } = Layout

interface BaseTemplateProps {
  children: React.ReactNode
}

const cerrarSesion = () => {
  logout()
  window.location.href = "/login"
}

const BaseTemplate: React.FC<BaseTemplateProps> = ({ children }) => {
  const { Title, Text } = Typography
  const { user } = useContext(AuthContext)
  const { titulo } = useGeneralContext()

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={false} theme="light">
        <LogoWrapper>
          <Image src={logo} />
        </LogoWrapper>
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<UserOutlined />}>
            <Link to="/">Usuarios</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<UserOutlined />}>
            <Link to="/posturas">Posturas</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<UserOutlined />}>
            <Link to="/productos">Productos</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<UserOutlined />}>
            <Link to="/reporte">Reporte</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <Space
            size="middle"
            direction={"vertical"}
            style={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Title level={3}>{titulo}</Title>
              <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <UserText>Usuario: {user?.nombre}</UserText>
                <LogoutButton onClick={cerrarSesion}>[→</LogoutButton>
              </div>
            </div>
          </Space>
        </Header>
        <Content>{children}</Content>
        <Footer>
          <Text>
            Desarrollado por GSiempre Software -{" "}
            <a href="https://www.gsiempre.com">gsiempre.com</a>
          </Text>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default BaseTemplate
